body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow-y: scroll;
}

.brandLogo {
  font-size: 1.4em;
  line-height: 32px;
}

.cardThumb img {
  display: none;
}

.cardBody {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 120px, rgba(255, 255, 255, 0.5) 100%);
  z-index: 2;
}

.cardBanner {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.contentList {
  display: none;
}

#brandLogo,
#loadingSpinner {
  height: 32px;
  width: 32px;
}

.jconfirm-content div {
  overflow-y: hidden;
}